class FilterModel {
    operationType;
    propertyTypes;
    propertySubTypes;
    propertyStates = [];
    MinPrice; 
    MaxPrice; 
    MinSize;
    MaxSize; 
    Bedrooms; 
    Baths; 
    HalfBaths;
    Parkings;
    own; 
    Latitude; 
    Longitude; 
    Radius; 
    SortType = 0;
    From = 0;
    Limit  = 10;
    MaxPostAgeInDays = null;
    Features = [];

    constructor(operationType, latitude, longitude, radius, propertyTypes){
     this.operationType = operationType;
     this.propertyTypes = propertyTypes;
     this.latitude = latitude;
     this.longitude = longitude;
     this.radius = radius;
    }
 
 }



 
 
 export default FilterModel;