import '../components/Header'
import '../styles/css/main.css'
import background from '../images/main_background.png'
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SelectorModel from '../data/models/selector';
import FilterModel from '../data/models/filters';
import { FormHelperText, ToggleButton, ToggleButtonGroup } from '@mui/material';
import axios, { isCancel, AxiosError } from 'axios';
import Select from 'react-select';
import Address from '../data/models/address';
import React from 'react';
import ReportIcon from '@mui/icons-material/Report';
import { useTranslation } from "react-i18next";
import i18next from 'i18next';
import Config from '../config';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByPlaceId } from 'react-google-places-autocomplete';
import img1 from '../images/img1.png';
import img2 from '../images/img2.png';
import img3 from '../images/img3.png';
import dot from '../images/Dot.png';
import appStore from "../images/app-store.png"
import googlePlay from "../images/google-play.png"


const googleMapsApiKey = Config.apiKeyGoogleMaps;

function Main() {
  const { t } = useTranslation();
  const [selectedPropertyType, setSelectedPropertyType] = useState('FFFFFFFF-FFFF-FFFF-FFFF-FFFFFFFFFFFF');
  const [selectedOperationType, setSelectedOperationType] = useState('AB94A3AB-1F99-43CC-800A-6CF9947981B2');
  const [propertyTypes, setOperationTypes] = useState([]);
  const [filters, setFilters] = useState(new FilterModel());
  const [addresses, setAddresses] = useState([new Address("nearby", t('nearby')), new Address("all", t('searchAll'))]);
  const [addresseSelected, setAddresseSelected] = useState(null);
  const [lat, setLat] = useState(0);
  const [lng, setLon] = useState(0);
  const [emptyLocation, setEmptyLocation] = useState(true);


  useEffect(() => {

    navigator.geolocation.getCurrentPosition((postion) => {
      setLat(postion.coords.latitude);
      setLon(postion.coords.longitude);
    })


    const lang = i18next.language;
    var filters = new FilterModel(selectedOperationType, 18.498233562134637, -68.44701893627644, 20);
    fetch(Config.apiUrl + "properties/filters?languageCode=" + lang)
      .then(response => response.json())
      .then(data => {
        const pt = data.data.propertyTypes.map(p => new SelectorModel(p.id, p.nombre));
        pt.push(new SelectorModel("FFFFFFFF-FFFF-FFFF-FFFF-FFFFFFFFFFFF", t('all')));
        setOperationTypes(pt);
        filters.propertyTypes = data.data.propertyTypes.map(p => p.id);
        setFilters(filters);
      })
  }, [])

  const navigate = useNavigate();

  function submitSearch() {


    if (addresseSelected == null) {
      setEmptyLocation(false);
      return;
    }

    if (addresseSelected.value == "nearby") {
      filters.latitude = lat;
      filters.longitude = lng;
      var jsonFilters = JSON.stringify(filters);
      localStorage.setItem("filters", jsonFilters);
      navigate(process.env.PUBLIC_URL + '/search/' + t('nearby'));
    }
    else  if (addresseSelected.value == "all") {
      filters.latitude = null;
      filters.longitude = null;
      filters.radius = null;
      var jsonFilters = JSON.stringify(filters);
      localStorage.setItem("filters", jsonFilters);
      navigate(process.env.PUBLIC_URL + '/search/' + t('searchAll'));
    }
    else {
      const place_id = addresseSelected.value;
      geocodeByPlaceId(place_id.place_id)
        .then((results) => {
          let lat = results[0].geometry.location.lat();
          let lng = results[0].geometry.location.lng()
          filters.latitude = lat;
          filters.longitude = lng;
          var jsonFilters = JSON.stringify(filters);
          localStorage.setItem("filters", jsonFilters);
          navigate(process.env.PUBLIC_URL + '/search/' + addresseSelected.value.description);
        }

        )
        .catch(error => console.error(error));
    }
  }

  function changePropertyType(propertyType) {
    setSelectedPropertyType(propertyType);
    filters.propertyTypes = [propertyType];
  }

  function changeOperationType(opType) {
    setSelectedOperationType(opType);
    filters.operationType = opType;
  }

  function searchAddress(value) {
    const query = value;
    const type = 'address';
    const region = 'do';
    // const proxy="https://cors-anywhere.herokuapp.com/";
    const proxy = "";
    var config = {
      method: 'get',
      url: `${proxy}https://maps.googleapis.com/maps/api/place/autocomplete/json?&input=${query}&components=country:DO&key=${googleMapsApiKey}`,
      headers: {}
    };

    axios(config)
      .then(function (response) {
        var options = response.data.predictions.map(y => new Address(y.place_id, y.description));
        if (value !== "") {
          setAddresses(options);
        }
        else {
          if (addresseSelected.label != null){
            setAddresses([new Address("nearby", t('nearby'))]);
            setAddresses([new Address("all", t('searchAll'))]);
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function onChangeAddressSelected(value, details) {

    setEmptyLocation(true);
    setAddresseSelected(value);
  }

  return (
    <>
   
      <div className="Main-container" >
         
      <img className='img-mob' src={background} />
        <div className='content-center' style={{ backgroundImage: `url(${background})`, backgroundSize: "cover", backgroundRepeat: 'no-repeat' }}>
          <div className='form-container bg-trans'>
            <h6 className='mb-3' style={{color: '#ED6A06'}}>Encuentra la casa perfecta para tu estilo de vida</h6>
            <ToggleButtonGroup className=' mb-3 switch-opt'
              value={selectedOperationType}
              exclusive
              onChange={e => changeOperationType(e.target.value)}>
              <ToggleButton  className='btn-switch buy' value="AB94A3AB-1F99-43CC-800A-6CF9947981B2">{t('buy')} </ToggleButton>
              <ToggleButton className='btn-switch rent' value="2848B78F-0341-4CBB-AD95-0C5408629901">{t('rent')} </ToggleButton>
            </ToggleButtonGroup>

            <select className="form-select form-select mb-3" value={selectedPropertyType}
              onChange={e => changePropertyType(e.target.value)}>
              {propertyTypes.map(o => (
                <option key={o.id} value={o.id}>{o.value}</option>
              ))}
            </select>

            <div className='mb-3'>
              <GooglePlacesAutocomplete onChange={onChangeAddressSelected} defaultOptions={addresses}
                GooglePlacesDetailsQuery={{ fields: "geometry" }}
                fetchDetails={true}
                autocompletionRequest={{
                  componentRestrictions: {
                    country: ["DO"]
                  }
                }}

                selectProps={{

                  defaultOptions: addresses,
                  onChange: onChangeAddressSelected,
                  placeholder: t('initMsg'),

                }}
                apiKey={Config.apiKeyGoogleMaps}
              />
              <FormHelperText hidden={emptyLocation} style={{ color: "red", fontSize: 14 }}><ReportIcon fontSize='small'></ReportIcon>{t('initMsg')}</FormHelperText>
            </div>

            <button style={{ fontWeight: 'bold' }} className='btn btn-clickclick' onClick={submitSearch}>{t('search')}</button>

          </div>
        </div>
      </div>

      <div className='bg-color-orage'>
        <div className='info-home-container pt-5 pb-5'>
          <h3 className='mb-3'>{t('text1')}</h3>
          <div>
            <p>{t('text2')}</p>
            <p>{t('text3')}</p>
            <p className='mb-0'>{t('text4')}</p>
          </div>
        </div>

        <div className=" apps-btn center pb-5">
            <a href="https://apps.apple.com/es/app/click-click-rd/id1626472011" className="app-store"><img src={appStore} alt="" /></a>
            <a href={Config.androidStoreUrl} className="app-store"><img src={googlePlay} alt="" /></a>
        </div>
      </div>

      <div className='bg-color-blue'>

        <div className='info-home-container pt-5 pb-5'>
          <h3 className='mb-3'>{t('text5')}<br></br>{t('text6')}</h3>
          <div>
            <p>{t('text7')}</p>
            <p>{t('text8')}</p>
            <p className='mb-0'>{t('text9')}</p>
          </div>
        </div>
      </div>

      <div className='mt-5 mb-5 news-container'>
        <div className="row featurette pb-5" >
          <div className="col-md-6 order-md-2 single-container">
            <h2 className="featurette-heading ">Home, sweet home.<br/> {t('text10')}  <span className="text-body-secondary">{t('text11')}</span></h2>
              <p>{t('text12')}</p>

              <p>{t('text13')}</p>

              <p>{t('text14')}</p>
          </div>
          <div className="col-md-6 order-md-1 ">
            <img src={img1} />
          </div>
        </div>

 
        <div className='bg-color-blue pt-5'>

        <div className="row featurette pb-5" >
          <div className="col-md-6 single-container" >
            <h2 className="featurette-heading" style={{color:'white'}}>
            <span className="text-body-secondary">{t('text15')}</span> <span className="text-body-secondary"></span>{t('text16')}</h2>
            <p>{t('text17')}</p>
            <p>{t('text18')}</p>
            <p>{t('text19')}</p>
          </div>
          <div className="col-md-6">
            <img src={img2} />
          </div>
        </div>
        </div>

        <div className="row featurette pt-5">
          <div className="col-md-6 order-md-2 single-container">
            <h2 className="featurette-heading">{t('text20')} <span className="text-body-secondary">{t('text21')}</span></h2>
            <p>{t('text22')}</p>
            <p>{t('text23')}</p>
            <p>{t('text24')}</p>
          <a style={{ fontWeight: 'bold', width: 200 }} className='btn btn-clickclick  mb-3' href="mailto:broker@clickclickrd.com">{t('contactnow')}</a>
          </div>
          <div className="col-md-6 order-md-1">
            <img src={img3} />
          </div>
        </div>
      </div>
    </>
  );
}

export default Main;