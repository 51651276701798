import * as React from 'react';



import axios from 'axios';
import '../components/Header'
import Header from '../components/Header';
import '../styles/css/main.css'
import { Alert, AppBar, Button, Collapse, Dialog, IconButton, Slide, TextField, Toolbar, Typography } from '@mui/material';
import style from '../styles/css/detail.css'
import CallIcon from '@mui/icons-material/Call';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from "react-i18next";

import Config from '../config';

export default function Contact({ property }) {

  const { t, i18next } = useTranslation();

  const [name, setName] = React.useState("");
  const [phone, setPhone] = React.useState("+34");
  const [email, setEmail] = React.useState("");
  const [msg, SetMsg] = React.useState(t('contactMsg'));

  const [validateErrorMsg, setValidateErrorMsg] = React.useState("");
  const [showValidationContactFormMsg, setShowValidationContactFormMsg] = React.useState(false);

  const [openContact, setOpenContact] = React.useState(false);
  const [showErrorContact, setshowErrorContact] = React.useState(false);
  function validateContactForm() {
    setShowValidationContactFormMsg(false);
    if (msg === "") {
      setValidateErrorMsg(t('emptyMsgError'));
      setShowValidationContactFormMsg(true);
      return false;
    }

    if (email === "" && (phone === "" || phone.length <= 9)) {
      setValidateErrorMsg(t('emailMsg'));
      setShowValidationContactFormMsg(true);
      return false;
    }

    if (email !== "") {
      let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!regEmail.test(email)) {
        setValidateErrorMsg(t('emailFormatError'));
        setShowValidationContactFormMsg(true);
        return false;
      }
    }

    if (name === "") {
      setValidateErrorMsg(t('nameMsg'));
      setShowValidationContactFormMsg(true);
      return false;
    }

    return true;
  }

  function onChangeEmail(value) {
    setEmail(value);
  }
  function onChangeName(value) {
    setName(value);
  }
  function onChangePhone(value) {
    setPhone(value);
  }
  function onChangeMsg(value) {
    SetMsg(value);
  }

  function openContactDialog() {

    if (!validateContactForm()) {
      return;
    }

    const headers = {
      "content-type": "application/json",
    };

    const url = Config.apiUrl + 'properties/contact';


    var contact = { "inmuebleId": property.id, "nombre": name, "email": email, "telefono": phone, "mensaje": msg };
    axios.post(url, contact, { headers })
      .then(response => {
        var res = response.data.data;
        if (response.status == 200) {
          setOpenContact(true);
        }
        else {
          setshowErrorContact(true);
        }

      }
      );
  }

  return (
    <div className='contact-container'>
      <div className='contact-msg' style={{ backgroundColor: "#fff" }}>
        <h3>
          <strong>{t('askAdvertiser')}</strong>
        </h3>
        <div className='row mt-3'>
          <TextField
            id="standard-multiline-static"
            label=""
            multiline
            rows={4}
            value={msg}
            variant="standard"
            onChange={e => onChangeMsg(e.target.value)}
          />
        </div>

        <div className=' mt-3'>
          <div className='col-md-12'>
            <TextField
              autoFocus
              margin="dense"
              id="email"
              label={t('yourEmail')}
              type="email"
              fullWidth
              variant="standard"
              value={email}
              onChange={e => onChangeEmail(e.target.value)}
              helperText=""/>
          </div>

          <div className='row mt-3'>
            <div className='col-md-7'>
              <TextField
                autoFocus
                margin="dense"
                id="phone"
                label={t('yourPhone')}
                type="tel"
                value={phone}
                variant="standard"
                onChange={e => onChangePhone(e.target.value)}/>
            </div>

            <div className='col-md-5'>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label={t('yourName')}
                type="text"
                value={name}
                variant="standard"
                onChange={e => onChangeName(e.target.value)} />
            </div>
          </div>
        </div>
        <div className='row mt-3'>
          <Collapse in={openContact}>
            <Alert
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpenContact(false);
                  }}>
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}>
              {t('msgSent')}
            </Alert>
          </Collapse>

          <Collapse in={showErrorContact}>
            <Alert severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setshowErrorContact(false);
                  }}>
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}>
              {t('msgError')}
            </Alert>
          </Collapse>

          <Collapse in={showValidationContactFormMsg}>
            <Alert severity="warning"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setShowValidationContactFormMsg(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }} >
              {validateErrorMsg}
            </Alert>
          </Collapse>
        </div>
        <div className='row mt-3'>
          <Button onClick={openContactDialog} variant="contained">{t('contact')}</Button>
        </div>
      </div>

      <div className='contact-msg' style={{ backgroundColor: "#F4F5F2" }}>
        <div className='row mt-3'>
          <a className='tocall' href={"tel:" + property.phone}><CallIcon></CallIcon> <span>{property.phone}</span> </a>
        </div>
        <div className='row mt-3'>
          <span className='gray-title'>{t('advertiser')}</span>
          <strong>{property.contact}</strong>
        </div>
      </div>
    </div>
  );
}