import i18next from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {

  en: {
    translation: {
      "downloadApp": "Download the App!",
      "buy": "To buy",
      "rent": "To rent",
      "all": "All",
      "nearby": "Nearby where you are",
      "searchAll": "Search all",
      "search": "Search",
      "searchInDR": "Search in Dominican Republic",
      "yourlastsearches": "Your last searches",
      "showing": "showing",
      "of": "of",
      "posts": "properties",
      "propertyop": "Operation type",
      "price": "Price",
      "size": "Size",
      "propertytype": "Property type",
      "rooms": "Bedrooms",
      "baths": "Bathrooms",
      "features": "Features",
      "state": "State",
      "publicationDate": "Publication date",
      "list": "Listing",
      "map": "Map",
      "sort": "Sort",
      "initMsg": "Enter a location where to search",
      "contacto": "CONTACT",
      "DominanRepublic": "Dominican Republic",
      "call": "Call",
      'filters': "Filters",

      //details
      "askAdvertiser": "Ask the advertiser",
      "yourEmail": "Your email",
      "yourPhone": "Your phone",
      "yourName": "Your name",
      "contact": "Contact",
      "advertiser": "Advertiser",
      "contactMsg": "Hello, I am interested in this property and I would like to make a visit.",
      "saveFavorite": "Save to favorites",
      "description": "Property description",
      "basicfeatures": "Basic features",
      "photos": "Photos",
      "room": "bed.",
      "emailMsg": "Please enter an email or a phone so you can receive a response",
      "nameMsg": "This doesn't look like a name",
      "emailFormatError": "Check your email",
      "emptyMsgError": "Message can't be empty.",
      "msgSent": "Your message have been sent successfully!",
      "msgError": "An error has occurred when sending the message!",
      "viewMap": "View map",
      "lastweek": "Last week",
      "last48h" : "Last 48h",
      "lastmonth": "Last month",
      "indifferent" : "Indifferent",
      "recents": "Recents",
      "leastrecent" : "Least recent",
      "lowestprice": "Lowest price",
      "highestprice": "Hightest price",
      "biggest": "Biggest",
      "smallest": "Smallest",
      "text1": "Click Click RD, the new App to buy, rent or sell properties",
      "text2": "Click Click RD is the reference App in the Dominican Republic to search for properties for sale or rent.",
      "text3": "Download the App now in your store and find your new home now!",
      "text4": "Available for Android. iOS coming soon!",
      "text5": "Do you need to sell or rent your apartment?",
      "text6": "Publish your ad for free!",
      "text7": "Why publish your ad on Click Click RD? We help you have more visibility to reach the greatest number of people and close your deal as soon as possible.",
      "text8": "To publish your ad you must download the App on your mobile device.",
      "text9": "Download the App now in your store and start selling or renting now!",
      "text10": "Find ",
      "text11": "your new home.", 
      "text12":"With our search system, easily find the apartment or villa you are looking for to buy or rent.",
      "text13": "Choose the type of property that best suits your needs and locate the area where you want to carry out your search on the map. Buy or rent easily.",
      "text14": "Use the filters in your search results to specify as much as possible what your new home should be like.",
      "text15": "Save your searches",
      "text16": "and choose your favorites. We will send you alerts when there are changes.",
      "text17": "The Click Click RD App has all the facilities for you to buy or rent the house of your dreams.",
      "text18": "Save your recent searches or the most outstanding ones. We will send you notices when there is news in the area.",
      "text19": "Have you seen a property you like? Save it to favorites and we'll let you know if the price changes.",
      "text20": "The new App for professional brokers. grow your",
      "text21": "real-estate agency.",
      "text22": "Do you want to increase the number of sales of your business? Publish your ads on Click Click RD and reach thousands of people every week.",
      "text23": "Grow your business with our packs and solutions designed exclusively for you.",
      "text24": "Contact our commercial department to register as a real estate agency or broker.",
      "contactnow": "Contact now",
    },
  },

  es: {
    translation: {
      "downloadApp": "¡Descarga la App!",
      "buy": "Comprar",
      "rent": "Alquilar",
      "all": "Todos",
      "nearby": "Cerca de donde estás",
      "searchAll": "Buscar todos",
      "search": "Buscar",
      "searchInDR": "Buscar en República Dominicana",
      "yourlastsearches": "Tus últimas búsquedas",
      "showing": "mostrando",
      "of": "de",
      "posts": "inmuebles",
      "propertyop": "Tipo de operación",
      "price": "Precio",
      "size": "Tamaño",
      "propertytype": "Tipo de propiedad",
      "rooms": "Habitaciones",
      "baths": "Baños",
      "features": "Características",
      "state": "Estado",
      "publicationDate": "Fecha de publicación",
      "list": "Listado",
      "map": "Mapa",
      "sort": "Ordenar",
      "initMsg": "Escribe una ubicación donde buscar",
      "contacto": "CONTACTO",
      "DominanRepublic": "República Dominicana",
      "call": "Llamar",
      'filters': "Filtros",

      //details
      "askAdvertiser": "Preguntar al anunciante",
      "yourEmail": "Tu correo",
      "yourPhone": "Tu teléfono",
      "yourName": "Tu nombre",
      "contact": "Contacto",
      "advertiser": "Anunciante",
      "contactMsg": "Hola, me interesa este inmueble y me gustaría hacer una visita. \nUn saludo.",
      "saveFavorite": "Guardar favorito",
      "description": "Comentario del anunciante",
      "basicfeatures": "Características básicas",
      "photos": "Fotos",
      "room": "hab.",
      "emailMsg": "Debe proporcionar un correo electrónico o teléfono para que podamos contactarlo.",
      "nameMsg": "Introduzca un nombre para poderle contactar.",
      "emailFormatError": "Revise el formato de su email.l",
      "emptyMsgError": "El mensaje no puede ser vacío.",
      "msgSent": "Su mensaje ha sido enviado correctamente!",
      "msgError": " Ha ocurrido un error al enviar el mensaje!",
      "viewMap": "Ver mapa",
      "lastweek": "La última semana",
      "last48h" : "Últimas 48h",
      "lastmonth": "El último mes",
      "indifferent" : "Indiferente",
      "recents": "Recientes",
      "leastrecent" : "Antiguos",
      "lowestprice": "Baratos",
      "highestprice": "Precio más alto",
      "biggest": "Grandes",
      "smallest": "Pequeños",
      "text1": "Click Click RD, la nueva App para comprar, alquilar o vender propiedades",
      "text2": "Click Click RD es la App de referencia en República Dominicana para buscar inmuebles en venta o alquiler.",
      "text3": "Descarga ahora la App en tu store y ¡encuentra ya tu nuevo hogar!",
      "text4": "Disponible para Android y próximamente disponible para iOS.",
      "text5": "¿Necesitas vender o alquilar tu piso? ",
      "text6": "¡Publica gratis tu anuncio!",
      "text7": "¿Por qué publicar tu anuncio en Click Click RD? Te ayudamos a tener más visibilidad para alcanzar al mayor número de personas y cierres tu trato cuanto antes.",
      "text8": "Para publicar tu anuncio debes descargar la App en tu dispositivo móvil.",
      "text9": "Descarga ahora la App en tu store y ¡empieza ya a vender o alquilar!",
      "text10": "Encuentra ",
      "text11": "tu nuevo hogar",
      "text12": "Con nuestro sistema de búsqueda, encuentra fácilmente el apartamento o villa que buscas para comprar o alquilar.",
      "text13": "Elige el tipo de propiedad que más se adapte a tus necesidades y ubica en el mapa la zona donde quieres realizar tu búsqueda. Compra o alquila fácilmente.",
      "text14": "Utiliza los filtros en los resultados de tu búsqueda para precisar al máximo cómo tiene que ser tu nueva vivienda.",
      "text15": "Guarda tus búsquedas",
      "text16": "y elige tus favoritos. Te mandaremos alertas cuando haya cambios.",
      "text17": "La App de Click Click RD dispone de todas las facilidades para que compres o alquiles la casa de tus sueños.",
      "text18": "Guarda tus búsquedas recientes o las más destacadas. Te mandaremos avisos cuando haya novedades en la zona.",
      "text19": "¿Has visto una propiedad que te gusta? Guárdala en favoritos y te avisaremos si cambia de precio.",
      "text20": "La nueva App para brokers profesionales. Haz crecer tu",
      "text21": "agencia inmobiliaria.",
      "text22": "¿Quieres incrementar el número de ventas de tu negocio? Publica tus anuncios en Click Click RD y llega a miles de personas cada semana.",
      "text23": "Haz crecer tu negocio con nuestros packs y soluciones pensadas exclusivamente para ti.",
      "text24": "Contacta con nuestro departamento comercial para darte de alta como agencia inmobiliaria o broker.",
      "contactnow": "Contacta ahora",
    },
  },
};
const DETECTION_OPTIONS = {
  order: ['localStorage', 'navigator'],
  caches: ['localStorage']
};

const lang = localStorage.getItem("lang") == undefined ? "es" : localStorage.getItem("lang")
i18next
  .use(initReactI18next)
  .init({
    resources,
    detection: DETECTION_OPTIONS,
    lng: lang,
    fallbackLng: lang,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18next;