import './App.css';
import './components/Header'
import './pages/Properties'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Properties from './pages/Properties';
import Main from './pages/Main';
import StorePage from './pages/StorePage';
import PropertyDetail from './pages/PropertyDetail';
import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../node_modules/bootstrap/dist/css/bootstrap.css"
import Header from './components/Header';
import Footer from './components/Footer';
import DownloadPage from './pages/Download';
import PrivacyPolicyPage from './pages/PrivacyPolicy';


var bgColor = function () { 
if(window.location.pathname==="/download")
{
  return "c";
}

if(window.location.pathname==="/privacy-policy"){
  return "white-bg";
}

else{
  return "bg-color";
}

 }

function App() {
  return (
    <>
      <BrowserRouter>
      <Header></Header>
        <div className={bgColor()}>
          <div className={window.location.pathname!=="/download" ? "main-box" : "box"}>
            <Routes>
              <Route path='/' element={<Main />} />
              <Route path='/search/:place' element={<Properties />} />
              <Route path='/storePage' element={<StorePage />} />
              <Route path='/details/:id' element={<PropertyDetail />} />
              <Route path='/download' element={<DownloadPage />} />
              <Route path='/privacy-policy' element={<PrivacyPolicyPage />} />
               {/* <Route path='/' element={<StorePage />} /> */}
            </Routes>
          </div>
          {/* <div className={"text-center p-3 uppercase text-sm text-white font-semibold my-6"}>
            &copy; ClickClickRD 2023
          </div> */}
        </div>
        <Footer/>
      </BrowserRouter>
    </>
  );
}

export default App;